import { MigrationHandler } from '../migration/migration-handler';
import { BOOKINGS_DEF_ID, PageId, WidgetsId } from '../constants';
import { addBookCheckoutPage } from './pages-actions';
import { addBookingsPagesAsPanel, createBookCheckoutState } from './pages-panel-actions';

export async function handleMigration(sdk, appToken) {
  const migratory = new MigrationHandler(sdk, appToken, BOOKINGS_DEF_ID);
  await migratory.migrateMainSectionTo(WidgetsId.BOOKINGS_LIST, PageId.BOOKINGS_LIST);
  const schedulerStyle = await migratory.getMainSectionStyle();
  await addBookCheckoutPage(sdk, BOOKINGS_DEF_ID, appToken, schedulerStyle);
  await addBookingsPagesAsPanel(sdk, BOOKINGS_DEF_ID, appToken);
  await createBookCheckoutState(sdk, appToken);
  return Promise.resolve();
}
