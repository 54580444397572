import { BOOKINGS_DEF_ID } from '../constants';

export async function addBookingsPagesAsPanel(editorSDK, appDefinitionId, appToken) {
  const bookingsTpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId(appToken, BOOKINGS_DEF_ID)).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll();
  const bookingsPages = allSitePages.filter(page => page.tpaApplicationId === bookingsTpaApplicationId && page.tpaPageId !== 'bookings_member_area');
  bookingsPages.forEach( async bookingPage => await editorSDK.pages.data.update(appToken, {pageRef: {id: bookingPage.id, type: bookingPage.type}, data: {managingAppDefId: appDefinitionId}}));
}

export async function createBookCheckoutState(editorSDK, appToken) {
  const allSitePages = await editorSDK.pages.data.getAll();
  const bookCheckoutPageData = allSitePages.find(page => page.tpaPageId === "book_checkout");
  if (bookCheckoutPageData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        bookCheckoutPage: [{id: bookCheckoutPageData.id}]
      }
    })
  }
}
