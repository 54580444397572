import {ComponentDataType, ComponentStructure, ComponentType, GeneralReference} from './domain';

export class MigrationHandler {
  constructor( private editorSDK, private appToken, private tpaId ) {}

  public async migrateMainSectionTo(newWidgetId, newPageId) {
    const tpaMainSectionStructure = await this.getTPAMainSectionStructure();
    const tpaMainSectionRef = await this.getSectionRef(tpaMainSectionStructure);
    await this.replaceMainSection(tpaMainSectionRef, newWidgetId);
    const tpaMainSectionPageRef: GeneralReference = await this.getPageRef(tpaMainSectionRef);
    await this.switchPageId(tpaMainSectionPageRef, newPageId);
  };

  public async getMainSectionStyle() {
    const tpaMainSectionStructure = await this.getTPAMainSectionStructure();
    return tpaMainSectionStructure.style
  }

  private async getTPAMainSectionStructure() {
    const allComponents = await this.editorSDK.document.components.getAllComponents(this.appToken);
    const siteStructure: any[] = await (async () => {
      return await Promise.all(allComponents.map(componentRef =>
        this.editorSDK.document.components.serialize(this.appToken, {componentRef: componentRef, maintainIdentifiers: true})
      ))
    })();
    const compStructures: ComponentStructure[] = siteStructure.filter(comp => comp.type === ComponentType.COMPONENT);

    return compStructures.find(comp => comp.data && comp.data.appDefinitionId === this.tpaId && comp.data.type === ComponentDataType.MAIN_SECTION);
  }

  private async replaceMainSection(tpaMainSectionRef, newWidgetId) {
    await this.editorSDK.components.data.update(this.appToken, {componentRef: tpaMainSectionRef, data: {widgetId: newWidgetId}});
  }

  private async switchPageId(pageRef: GeneralReference, newPageId) {
    await this.editorSDK.pages.data.update(this.appToken, {pageRef, data: {tpaPageId: newPageId}});
  }

  private getSectionRef(sectionStructure: ComponentStructure): Promise<GeneralReference> {
    return this.editorSDK.document.components.getById(this.appToken, {id: sectionStructure.id});
  }

  private getPageRef(sectionRef: GeneralReference): Promise<GeneralReference> {
    return this.editorSDK.components.getPage(this.appToken, {componentRef: sectionRef});
  }
}
