import { Translations } from './utils/translations';
import { BOOKINGS_DEF_ID, MEMBERS_AREA_DEF_ID } from './constants';
import { addBookingsMembersArea, installMembersArea } from './utils/members-area-actions';
import { addBookingsPagesAsPanel, createBookCheckoutState } from './utils/pages-panel-actions';
import { handleMigration } from './utils/migrate-actions';

export const createEditorScript = withSplit => {
  let appToken;
  let sdk;
  const bookingsDashboardPanel = 'bookings/scheduler/owner/offerings';
  const manageBookingsEvent = 'manageBookings';
  const deleteBookingsEvent = 'deleteBookings';
  const bookingsListPageId = 'bookings_list';
  const translation = new Translations();

  return {
    editorReady: async (_editorSDK, _appToken, options) => {
      await translation.editorInit(await _editorSDK.environment.getLocale(), '/');
      return new Promise(async (resolve) => {
        appToken = _appToken;
        sdk = _editorSDK;
        const isAdi = options.origin && options.origin.type === 'ADI';
        if (options && options.firstInstall) {
          if (withSplit) {
            await addBookingsPagesAsPanel(sdk, BOOKINGS_DEF_ID, appToken);
          }
          if (!isAdi) {
            await installMembersArea(sdk, appToken);
          }
        }
        await createBookCheckoutState(sdk, appToken);
        resolve();
      });
    },
    getAppManifest: () => {
      return {
        pages: {
          pageActions: {
            default:  {defaultValues:[
                'Pages_Actions_Page_Rename',
              ]}
          },
          pageSettings: {
            default: [
                {
                  title: translation.t('bookings-pages.tabs.page-info'),
                  helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                  type: 'page_info'
                },
                {
                  title: translation.t('bookings-pages.tabs.layout'),
                  type: 'layout'
                },
                {
                  title: translation.t('bookings-pages.tabs.permissions'),
                  type: 'permissions'
                },
                {
                  title: translation.t('bookings-pages.tabs.seo'),
                  type: 'seo'
                }
              ],
            bookCheckoutPage: [
                {
                  title: translation.t('bookings-pages.tabs.page-info'),
                  url: 'https://bookings.wixapps.net/bookings-widget/book-checkout-page-info',
                  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
                  type: 'page_info'
                },
                {
                  title: translation.t('bookings-pages.tabs.layout'),
                  type: 'layout'
                },
                {
                  title: translation.t('bookings-pages.tabs.permissions'),
                  type: 'permissions'
                }
              ]
          },
          applicationSettings: {
            default: {
              displayName: translation.t('bookings-pages.title'),
              helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33'
            }
          },
          applicationActions: {
            default: {
              defaultValues: [{
                title: translation.t('bookings-pages.actions.manage'),
                event: manageBookingsEvent,
                icon: 'settingsAction'
              },
              {
                title: translation.t('bookings-pages.actions.delete'),
                event: deleteBookingsEvent,
                icon: 'deleteRadio'
              }]
            }
          },
          pageDescriptors: {
            default: {
              icon: 'bookingPageType'
            }
          },
        }
      }
  },
    onEvent: async ({eventType, eventPayload}, editorSDK) => {
      switch (eventType) {
        case manageBookingsEvent:
          editorSDK.editor.openDashboardPanel(appToken, {url: bookingsDashboardPanel, closeOtherPanels: false});
          break;
        case deleteBookingsEvent:
          const bookingsPages = await editorSDK.document.pages.getApplicationPages(appToken);
          const essentialPage = bookingsPages.find(page => page.tpaPageId === bookingsListPageId);
          editorSDK.document.pages.remove(appToken, {pageRef : {id: essentialPage.id}});
          break;
      }
    },
    handleAction: async (args) => {
      const type = args.type, payload = args.payload;
      try {
        switch (type) {
          case 'migrate':
            console.log('migrating');
            return handleMigration(sdk, appToken);
          case 'appInstalled':
            switch (payload.appDefinitionId) {
              case MEMBERS_AREA_DEF_ID: {
                return addBookingsMembersArea(sdk, appToken);
              }
              default:
                return Promise.resolve();
            }
          default:
            return Promise.resolve();
        }
      } catch (e) {
        Promise.reject(e);
        throw e;
      }
    },
    getControllerPresets: () => {
      return Promise.resolve([]);
    }
  };
};
